// state
export const state = () => ({
	loading: false,
	inquieryLoading: false,
	blowerLoading: false,
	information: {},
	faqs: [],
	whistleBlowerSubjects: [],
	generalInquirySubjects: [],
})

// Actions
export const actions = {

	// Get contact us information
	async getContactUsInfo({ commit }) {
		try {
			commit('setLoading', true)
			await this.$axios.get(`/contact-info`).then(response => {
				commit('setInformation', response.data.data)
				commit('setLoading', false)
			}).catch(() => {
				commit('setLoading', false)
			})
		} catch (error) {
			commit('setLoading', false)
		}

	},

	// Get faq
	async getFAQ({ commit }) {
		try {
			await this.$axios.get(`/faq`).then(response => {
				commit('setFaq', response.data.data)
			}).catch(() => false)
		} catch (error) {
		}

	},

	// Get general inquiry subject
	async getGeneralInquirySubject({ commit }) {
		try {
			await this.$axios.get(`/subject?type=general_inquiry`).then(response => {
				commit('setGeneralInquirySubject', response.data.data)
			}).catch(() => false)
		} catch (error) {
		}

	},

	// Get whistle blower subject
	async getWhistleBlowerSubject({ commit }) {
		try {
			await this.$axios.get(`/subject?type=whistle_blower`).then(response => {
				commit('setWhistleBlowerSubject', response.data.data)
			}).catch(() => false)
		} catch (error) {
		}

	},

	// Submit general inquiry
	async doSubmitGeneralInquiry({ commit }, form) {
		try {
			const data = { ...form }
			const formData = new FormData()
			for (const key in data) {
				if (data[key]) {
					formData.append(key, data[key]);
				}
			}
			commit('setInquiryLoading', true)
			const response = await this.$axios
				.post(`/general-inquiry`, formData)
				.then((res) => {

					commit('setInquiryLoading', false)
					this.$toast.success('Submit General Inquiry Successfully')
					return true
				}).catch(() => {
					commit('setInquiryLoading', false)
					return false;
				})
			return response
		} catch (error) {
			commit('setInquiryLoading', false)
			return false;
		}
	},

	// Submit whistle blower
	async doSubmitWhistleBlower({ commit }, form) {
		try {
			const data = { ...form }
			const formData = new FormData()
			for (const key in data) {
				if (data[key]) {
					formData.append(key, data[key]);
				}
			}
			commit('setBlowerLoading', true)
			const response = await this.$axios
				.post(`/whistle-blower`, formData)
				.then((res) => {
					commit('setBlowerLoading', false)
					this.$toast.success('Submit Whistle Blower Successfully')
					return true
				}).catch(() => {
					commit('setBlowerLoading', false)
					return false;
				})
			return response
		} catch (error) {
			commit('setBlowerLoading', false)
			return false;
		}
	},

}

// Mutations
export const mutations = {
	setLoading(state, val) {
		state.loading = val
	},
	setInquiryLoading(state, val) {
		state.inquiryLoading = val
	},
	setBlowerLoading(state, val) {
		state.blowerLoading = val
	},
	setInformation: (state, val) => {
		state.information = val
	},
	setFaq: (state, val) => {
		state.faqs = val
	},
	setGeneralInquirySubject: (state, val) => {
		state.generalInquirySubjects = val
	},
	setWhistleBlowerSubject: (state, val) => {
		state.whistleBlowerSubjects = val
	},
}


// Getters
export const getters = {
	loading: state => state.loading,
	inquiryLoading: state => state.inquiryLoading,
	blowerLoading: state => state.blowerLoading,
	information: state => state.information,
	faqs: state => state.faqs,
	generalInquirySubjects: state => state.generalInquirySubjects,
	whistleBlowerSubjects: state => state.whistleBlowerSubjects
}