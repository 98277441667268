// state
export const state = () => ({
	loading: false,
	careers: {},
	careerDetail: {}
})

// Actions
export const actions = {

	// Get career
	async getCareer({ commit }, pageNo) {
		try {
			commit('setLoading', true)
			await this.$axios.get(`/jobs?page=${(pageNo || 1)}&limit=10`).then(response => {
				commit('setCareer', response.data.data)
				commit('setLoading', false)
			}).catch(() => {
				commit('setLoading', false)
			})
		} catch (error) {
			commit('setLoading', false)
		}

	},

	// Get career detail
	async getCareerDetail({ commit }, careerId) {
		try {
			commit('setLoading', true)
			await this.$axios.get(`/jobs/${(careerId)}`).then(response => {
				commit('setCareerDetail', response.data.data)
				commit('setLoading', false)
			}).catch(() => {
				commit('setLoading', false)
			})
		} catch (error) {
			commit('setLoading', false)
		}

	},
}

// Mutations
export const mutations = {
	setLoading(state, val) {
		state.loading = val
	},
	setCareer: (state, val) => {
		state.careers = val
	},
	setCareerDetail: (state, val) => {
		state.careerDetail = val
	}
}


// Getters
export const getters = {
	loading: state => state.loading,
	careers: state => state.careers,
	careerDetail: state => state.careerDetail,
}