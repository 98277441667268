
import { mapGetters } from "vuex";
export default {
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            loading: "setting/loading",
        }),
    },
    beforeCreate() {
        this.$vuetify.breakpoint.mobile = false;
    },
    mounted() {
        this.$store.dispatch("setting/getLanguages");
        this.$store.dispatch("setting/getTranslation");
        this.$store.dispatch("home/getMenu");
        this.$store.dispatch("home/getSlider");
    },
};
