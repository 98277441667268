import Vue from "vue"
import { mapGetters } from 'vuex'
import { Skeleton } from 'vue-loading-skeleton';

if (!Vue.__global_mixin_) {
	Vue.__global_mixin_ = true
	Vue.mixin({
		components: {
			Skeleton
		},
		computed: {
			...mapGetters({
				langLocale: "setting/language",
				languages: "setting/languages",
				translationStrings: "setting/translations",
				pdfSource: "setting/pdfSource",
			}),
			languageFlags() {
				return this.languages
			},
			currentFlag() {
				const localeCode = this.langLocale;
				if (!localeCode) return null;
				const currentLocaleData = this.languageFlags.find(
					(el) => el.code === localeCode
				);
				if (!currentLocaleData) return null;
				// return `${this.$config.BUCKET}/languages/${currentLocaleData.flag}`;
				return `/languages/${currentLocaleData.code}.png`;
			},
		},
		mounted() {
			if (process.env.NODE_ENV === 'production') {
				// const host = window.location.host
				// const hostSplit = host.split('.')

				// .length -2 is to get domain name
				// .length -1 is to get top level domain name
				// const domain = `${hostSplit[hostSplit.length - 2]}.${hostSplit[hostSplit.length - 1]}`

				// set these config file according to the domain name
				// Just for testing in production, after it works, it will be changed
				if (this.$config) {
					// this.$config.BUCKET = `https://cdn.${domain}/${this.$config.CLIENT_CODE}`
					// this.$config.LANDING_DOMAIN = `${domain}`
					// this.$config.API_DOMAIN = `https://api.${domain}`
					// this.$config.BUCKET = `https://storage.googleapis.com/logcc_public`
					// this.$config.API_DOMAIN = `https://api.logc.la`
					this.$config.FAVICON = `/favicon.ico`
				}
				/* if (this.$config && this.$config.io) {
					this.$config.io.sockets = [
						{
							default: true,
							name: 'main',
							url: `https://socket.${domain}`,
						},
					]
				} */
			} else if (this.$config) {
				// In case in localhost
				// For commit
				// this.$config.BUCKET = `https://storage.googleapis.com/logcc_public`
				// this.$config.API_DOMAIN = `https://api.logc.la`
				this.$config.FAVICON = `/favicon.ico`
				/* this.$config.io.sockets = [
					{
						default: true,
						name: 'main',
						url: 'https://socket.wing666.com',
					},
				] */
			}


		},
		methods: {
			goBack() {
				return this.$router.go(-1)
			},
			getNextLang(locale) {
				const localeIndex = this.languages.findIndex(
					(lang) => lang.code === locale
				);
				let nextLang = null;
				nextLang = this.languages[localeIndex + 1];
				if (!nextLang) {
					nextLang = this.languages[0];
				}
				return nextLang.code
			},
			onSwitchLang(locale) {
				const localeIndex = this.languages.findIndex(
					(lang) => lang.code === locale
				);
				let nextLang = null;
				nextLang = this.languages[localeIndex + 1];
				if (!nextLang) {
					nextLang = this.languages[0];
				}
				if (nextLang && nextLang.code) {
					this.$cookie.set("locale", nextLang.code);
					this.$store.commit("setting/setLanguage", nextLang.code);
				} else {
					this.$cookie.set("locale", "en-US");
					this.$store.commit("setting/setLanguage", "en-US");
				}
			},
			tl(keyCode) {
				if (!this.translationStrings.length) return keyCode
				const foundTl = this.translationStrings.filter(lang => lang.code === keyCode)
				if (foundTl.length) {
					const tlObject = foundTl[0]
					return tlObject?.value[this.langLocale] ? tlObject?.value[this.langLocale] : keyCode
				} else if (keyCode) {
					const newTranslate = {
						code: keyCode,
						value: {}
					}
					for (const lang of this.languages) {
						newTranslate.value[lang.code] = keyCode
					}
					// this.$store.dispatch("setting/doSaveTranslation", newTranslate);
				}
				return keyCode
			},
			bindHeadingTagToVuetifyHeadingClass(content) {
				return content
					.replace(/<h1>/gi, `<h1 class="text-h3">`)
					.replace(/<h2>/gi, `<h2 class="text-h4">`)
					.replace(/<h3>/gi, `<h3 class="text-h5">`)
					.replace(/<h4>/gi, `<h4 class="text-h6">`)
			},
			convertEmbedUrl(url) {
				return url
					.replace('/watch?v=', '/embed/') // for youtube url embeded
			},
			gotoPage(routePath) {
				this.$router.push({ path: routePath })
			}
		}
	})
}