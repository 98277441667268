// state
export const state = () => ({
	loading: false,
	cloading: false,
	openProjects: {},
	closeProjects: {},
	projectDetail: {}
})

// Actions
export const actions = {

	// Get open project bidding
	async getOpenProject({ commit }, pageNo) {
		try {
			commit('setLoading', true)
			await this.$axios.get(`/project-biding?page=${(pageNo || 1)}&limit=10&is_open=true`).then(response => {
				commit('setOpenProject', response.data.data)
				commit('setLoading', false)
			}).catch(() => {
				commit('setLoading', false)
			})
		} catch (error) {
			commit('setLoading', false)
		}

	},

	// Get close project bidding
	async getCloseProject({ commit }, pageNo) {
		try {
			commit('setCLoading', true)
			await this.$axios.get(`/project-biding?page=${(pageNo || 1)}&limit=10&is_open=false`).then(response => {
				commit('setCloseProject', response.data.data)
				commit('setCLoading', false)
			}).catch(() => {
				commit('setCLoading', false)
			})
		} catch (error) {
			commit('setCLoading', false)
		}

	},

	// Get project bidding detail
	async getProjectDetail({ commit }, projectId) {
		try {
			commit('setLoading', true)
			await this.$axios.get(`/project-biding/${(projectId)}`).then(response => {
				commit('setProjectDetail', response.data.data)
				commit('setLoading', false)
			}).catch(() => {
				commit('setLoading', false)
			})
		} catch (error) {
			commit('setLoading', false)
		}

	},
}

// Mutations
export const mutations = {
	setLoading(state, val) {
		state.loading = val
	},
	setCLoading(state, val) {
		state.cloading = val
	},
	setOpenProject: (state, val) => {
		state.openProjects = val
	},
	setCloseProject: (state, val) => {
		state.closeProjects = val
	},
	setProjectDetail: (state, val) => {
		state.projectDetail = val
	},
}


// Getters
export const getters = {
	loading: state => state.loading,
	cloading: state => state.cloading,
	openProjects: state => state.openProjects,
	closeProjects: state => state.closeProjects,
	projectDetail: state => state.projectDetail,
}