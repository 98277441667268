// state
export const state = () => ({
	loading: false,
	menus: [],
	sliders: []
})

// Actions
export const actions = {
	// Get Home Meun
	async getMenu({ commit }) {
		try {
			commit('setLoading', true)
			await this.$axios.get('/menubar').then(response => {
				commit('setMenu', response.data.data)
				commit('setLoading', false)
			}).catch(() => {
				commit('setLoading', false)
			})
		} catch (error) {
			commit('setLoading', false)
		}

	},

	// Get Home Slider
	async getSlider({ commit }) {
		try {
			commit('setLoading', true)
			await this.$axios.get('/slider').then(response => {
				commit('setSlider', response.data.data)
				commit('setLoading', false)
			}).catch(() => {
				commit('setLoading', false)
			})
		} catch (error) {
			commit('setLoading', false)
		}

	},
}

// Mutations
export const mutations = {
	setLoading(state, val) {
		state.loading = val
	},
	setSlider: (state, val) => {
		state.sliders = val
	},
	setMenu: (state, val) => {
		state.menus = val
	},
}


// Getters
export const getters = {
	loading: state => state.loading,
	sliders: state => state.sliders,
	menus: state => state.menus
}