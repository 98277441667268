// state
export const state = () => ({
	loading: false,
	licenses: []
})

// Actions
export const actions = {

	// Get License display or approved license
	async getLicense({ commit }) {
		try {
			commit('setLoading', true)
			await this.$axios.get(`/pra/approved-license`).then(response => {
				commit('setLicense', response.data.data)
				commit('setLoading', false)
			}).catch(() => {
				commit('setLoading', false)
			})
		} catch (error) {
			commit('setLoading', false)
		}

	},
}

// Mutations
export const mutations = {
	setLoading(state, val) {
		state.loading = val
	},
	setLicense: (state, val) => {
		state.licenses = val
	}
}


// Getters
export const getters = {
	loading: state => state.loading,
	licenses: state => state.licenses
}