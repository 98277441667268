// state
export const state = () => ({
	loading: false,
	categories: [],
	news: {},
	newsDetail: {}
})

// Actions
export const actions = {

	// Get Category
	async getCategory({ commit }) {
		try {
			await this.$axios.get(`/article-category`).then(response => {
				commit('setCategory', response.data.data)
			}).catch(() => false)
		} catch (error) {
		}

	},

	// Get News
	async getNews({ commit }, pageNo) {
		try {
			commit('setLoading', true)
			await this.$axios.get(`/article?page=${(pageNo || 1)}&limit=10`).then(response => {
				commit('setNews', response.data.data)
				commit('setLoading', false)
			}).catch(() => {
				commit('setLoading', false)
			})
		} catch (error) {
			commit('setLoading', false)
		}

	},

	// Get News By Category
	async getNewsByCategory({ commit }, { pageNo, categoryId }) {
		try {
			commit('setLoading', true)
			await this.$axios.get(`/article?category_id=${(categoryId)}&page=${(pageNo || 1)}&limit=10`).then(response => {
				commit('setNews', response.data.data)
				commit('setLoading', false)
			}).catch(() => {
				commit('setLoading', false)
			})
		} catch (error) {
			commit('setLoading', false)
		}

	},

	// Get news detail
	async getNewsDetail({ commit }, newsId) {
		try {
			commit('setLoading', true)
			await this.$axios.get(`/article/${(newsId)}`).then(response => {

				commit('setNewsDetail', response.data.data)
				commit('setLoading', false)
			}).catch(() => {
				commit('setLoading', false)
			})
		} catch (error) {
			commit('setLoading', false)
		}

	},
}

// Mutations
export const mutations = {
	setLoading(state, val) {
		state.loading = val
	},
	setCategory: (state, val) => {
		state.categories = val
	},
	setNews: (state, val) => {
		state.news = val
	},
	setNewsDetail: (state, val) => {
		state.newsDetail = val
	}
}


// Getters
export const getters = {
	loading: state => state.loading,
	categories: state => state.categories,
	news: state => state.news,
	newsDetail: state => state.newsDetail,
}