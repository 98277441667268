// state
export const state = () => ({
	loading: false,
	languages: [],
	translations: [],
	language: 'en-US',
	pdfSource: {}
})

// Actions
export const actions = {
	// Get languages
	async getLanguages({ commit }) {
		await this.$axios.get('/languages').then(response => {
			const languageFlags = response.data.data
			const foundLangs = languageFlags.filter(lng => lng.code === this.$cookie.get('locale'))
			if (foundLangs.length) {
				commit("setLanguage", this.$cookie.get('locale'));
			} else {
				this.$cookie.set('locale', 'en-US')
				commit("setLanguage", "en-US");
			}
			commit('setLanguages', languageFlags)
		}).catch(() => false)
	},

	// Insert translation data
	async doSaveTranslation({ commit }, form) {
		await this.$axios.post('/language-string', form, {
			headers: {
				'Content-Type': 'Application/json'
			}
		}).then(response => {
			const data = response.data.data
			if (data?.id && data?.code && data?.value) {
				commit('setPushTranslation', data)
			}
		}).catch(() => false)
	},

	// Get translation data
	async getTranslation({ commit }) {
		commit('setLoading', true)
		await this.$axios.get('/languages/string').then(response => {
			commit('setTranslation', response.data.data)
			commit('setLoading', false)
		}).catch(() => {
			commit('setLoading', false)
		})
	},
}

// Mutations
export const mutations = {
	setLoading(state, val) {
		state.loading = val
	},
	setLanguages: (state, v) => {
		state.languages = v
	},
	setTranslation: (state, v) => {
		state.translations = v
	},
	setPushTranslation: (state, v) => {
		state.translations.push(v)
	},
	setLanguage: (state, v) => {
		state.language = v
	},
	setPdfSource: (state, v) => {
		state.pdfSource = v
	},
}


// Getters
export const getters = {
	loading: state => state.loading,
	languages: state => state.languages,
	translations: state => state.translations,
	language: state => state.language,
	pdfSource: state => state.pdfSource,
}