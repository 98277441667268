// state
export const state = () => ({
	loading: false,
	policies: []
})

// Actions
export const actions = {

	// Get policy
	async getPolicy({ commit }) {
		try {
			commit('setLoading', true)
			await this.$axios.get(`/pra/policy`).then(response => {
				commit('setPolicy', response.data.data)
				commit('setLoading', false)
			}).catch(() => {
				commit('setLoading', false)
			})
		} catch (error) {
			commit('setLoading', false)
		}

	},
}

// Mutations
export const mutations = {
	setLoading(state, val) {
		state.loading = val
	},
	setPolicy: (state, val) => {
		state.policies = val
	}
}


// Getters
export const getters = {
	loading: state => state.loading,
	policies: state => state.policies
}