export default function (context) {
	context.$axios.onRequest((config) => {
		config.baseURL = context.app.$config.API_DOMAIN
		// config.baseURL = "https://api.lcm.la"
		// config.headers.common.Authorization = `${context.$cookie.get('token')}`
	})
	context.$axios.onError((error) => {

		// New code add by Youa
		/* context.$toast.clear();
		if (error?.response && error?.response?.data?.message) {
			const languageStrings = context.store.getters['language-strings/localeLanguage'](context.$cookie.get('locale') || 'en-US')
			const text = languageStrings?.find(el => el.code === error.response.data.message)
			if (text) {
				context.$toast.error(text.text || error.response.data.message)
			} else {
				context.$toast.error(error.response.data.message)
			}
		} else {
			context.$toast.error('Contact developers, Services are updating')
		} */
		// New code add by Youa

		//   Sending the toast messages.
		context.$toast.error(error.response.data.error || error)

		/* //   Once user Unauthorized then moved them to login page.
		if (error.response.status === 401) {
			context.store.dispatch('user/logout')
		} */

	})
}
